import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css, keyframes } from 'styled-components';

export default function Gallery ({images,altText,keyId,interval,autoplay}) {

    const [active, setActive] = useState(0);
    const [direction, setDirection] = useState('normal');

    useEffect(()=>{
        if(autoplay){
            const intervalId = setInterval(() => {
                let nextSlide = (active === images.length-1 ? 0 : active+1);
                setActive(nextSlide);
            }, interval);
            return () => clearInterval(intervalId);
        }
    },[active,images,interval,autoplay]);

    return (
        <GalleryImageWrapper className="owl-carousel owl-carousel-autoplay owl-theme">
            {images.map((image,i)=>{
                return (
                    <div key={`${keyId}-${i}`}>
                        <GalleryImage 
                            src={image} 
                            className='lazyload img-responsive' 
                            alt={altText} 
                            slideIn={(active===i)} 
                            slideOut={(active===(i+1))} 
                            direction={direction}
                        />
                    </div>        
                )
            })}
                <div className="nav">
                    {active>0 ? 
                        <a href="#top" className="prev" onClick={(e)=>{
                            e.preventDefault();
                            setDirection((active-1===0?'normal':'reverse'));
                            setActive((active - 1));
                        }}
                        ><i className="arrow left"/> </a> : <div className="placeholder"> </div>
                    }
                    <div className="nobs">
                        {images.map((image,i)=>{
                            return (
                                <a href="#top" onClick={(e)=>{
                                    e.preventDefault();
                                    setActive(i);
                                }}><i className={(active===i?'dot active':'dot')}/> </a>
                            )
                        })}
                    </div>
                    {active < images.length-1 ? 
                        <a href="#top" className="next" onClick={(e)=>{
                            e.preventDefault();
                            setDirection('normal');
                            setActive((active + 1));
                        }} 
                        ><i className="arrow right"/> </a> : <div className="placeholder"> </div>
                    }
                </div>
        </GalleryImageWrapper>
    );
}

Gallery.propTypes = {
    images:     PropTypes.array.isRequired,
    altText:    PropTypes.string.isRequired,
    keyId:      PropTypes.string.isRequired,
    interval:   PropTypes.number,
    autoplay:   PropTypes.bool
};

Gallery.defaultProps = {
    interval:   3000,
    autoplay: true
}

const slideIn = keyframes`
    100% { transform: translateX(0%); }
`;

const slideOut = keyframes`
    0% { transform: translateX(0%); }
    100% { transform: translateX(100%); }
`;

const GalleryImage = styled.img`
    opacity: 0;
    position: absolute;
    overflow: hidden;
    transform: translateX(-100%);

    ${ props => props.slideIn && css`
        opacity: 1;
        animation: ${slideIn} 1s forwards;
        animation-direction: ${props.direction};
    `};

    ${ props => props.slideOut && css`
        opacity: 1;
        animation: ${slideOut} 1s forwards;
        animation-direction: ${props.direction};
    `};
`;

const GalleryImageWrapper = styled.div`
    position: relative;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .nav {
        display: flex;
        justify-content: space-between;
        position: absolute;
        width: 100%;
        height: 100%;
        > div {
            height: 100%;
            text-align: center;
        }
        .placeholder { width: 85px; }
        .prev, .next {
            width: 85px;
            padding-top: 30%;
            text-align: left;
            cursor: pointer;
        }
        .next {
            text-align: right;
        }
        .prev:hover {
            background: rgb(0,0,0);
            background: linear-gradient(270deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.2) 100%);
        }
        .next:hover {
            background: rgb(0,0,0);
            background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.2) 100%);
        }
    }

    .arrow {
        border: solid #e43636;
        border-width: 0 4px 4px 0;
        display: inline-block;
        padding: 3px;
        margin: 0 10px;
    }
    .nav div:hover .arrow {
        border: solid white;
        border-width: 0 4px 4px 0;
    }
    .right {
        transform: rotate(-45deg);
    }
    .left {
        transform: rotate(135deg);
    }
    .nobs {
        flex-grow: 8;
        display: flex;
        align-items: flex-end;
        justify-content: center;
    }
    .nobs a { text-decoration: none; margin: 5px;}
    .dot {
        height: 10px;
        width: 10px;
        background-color: #bbb;
        border-radius: 50%;
        display: inline-block;
    }
    .dot.active {
        background-color: #e43636;   
    }

    @media (max-width: 991px) {
        padding-bottom: 56%;
    }
`;